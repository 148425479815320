.padding {
  padding-left: 1%;
  padding-right: 1%;
}

.startGame {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

ion-modal#results-modal {
  --width: 300px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: 0.9;
}

ion-modal#results-modal h1,
ion-modal#results-modal p {
  margin: 20px 20px 10px 20px;
}

ion-modal#results-modal .wrapper {
  margin-bottom: 10px;
}

.socials {
  margin: 20px 20px 10px 20px;
}

.social-button {
  margin-right: 10px;
}

.title {
  font-family: "leckerlione";
  font-size: 1.8em;
  color: var(--ion-color-primary);
}

ion-textarea.custom {
  --background: #373737;
  --color: #fff;
  --padding-end: 10px;
  --padding-start: 10px;
  --placeholder-color: #ddd;
  --placeholder-opacity: 0.8;
}
