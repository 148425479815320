.validate {
  background-color: #21f140 !important;
}

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  padding: 0 5%;
  height: 100%;
  overflow: auto;
  overflow-x: hidden;
  background-color: white;
  color: black;
}

.letter.box {
  border: 1px solid gray;
  background-color: var(--ion-color-primary-tint);
}
.letter.vowel {
  border: 1px solid gray;
  background-color: var(--ion-color-primary);
}

.text {
  margin-top: 1em;
  margin-bottom: 3em;
}

@media (max-width: 1500px) {
  .letter {
    display: inline-block;
    font-size: 1.7em;
    padding: 0.2em;
    min-width: 1.2em;
  }

  .word {
    display: inline-block;
    margin: 0.5em 0.5em 0.5em 0.5em;
  }
}

@media (min-width: 1500px) {
  .letter {
    display: inline-block;
    font-size: 2.5em;
    padding: 0.2em;
    min-width: 1.2em;
  }

  .word {
    display: inline-block;
    margin: 1em 1em 1em 1em;
  }
}
